<template>
  <!--      Modal Actual -->
  <b-modal
    id="view-feedback-notifications-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    :title="title"
  >
    <section>
      <b-card
        no-body
      >
        <b-card-body
          class="m-2"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="pt-1"
            >
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Delivery Service Feedback:
                      </td>
                      <td class="pl-2">
                        <span
                          v-if="notification.message"
                          v-html="notification.message" />
                        <span v-else>N/A</span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="pr-1 pt-2"
                        style="width: 200px;"
                      >
                        Software Feedback:</td>
                      <td class="pl-2 pt-2">
                        <span
                          v-if="notification.software_feedback"
                          v-html="notification.software_feedback"/>
                        <span v-else>N/A</span>
                      </td>
                    </tr>
                    <tr style="margin-bottom:3px;">
                      <td class="pr-1 pt-2">
                        Sent Date:
                      </td>
                      <td class="pl-2 pt-2">
                        <span>{{ __dateTimeFormatter(notification.created_at) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <div class="d-flex justify-content-end">
        <b-col md="4">
          <!-- Button: Close -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-success"
            class="mb-75"
            block
            @click="open=false"
          >
            Close
          </b-button>
        </b-col>
      </div>

    </section>
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BCol,
  BCardBody,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

function initialState() {
  return {
    open: false,
    notification: {},
    title: 'View Feedback',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BModal,
    BCol,
    BCardBody,
    BRow,
  },
  data() {
    return initialState()
  },
  methods: {
    async openModal(notification, title) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.notification = notification
      this.title = title
    },
  },
}
</script>
