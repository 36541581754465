<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant Name"
            label-for="merchant_id"
          >
            <v-select
              v-model="filters['merchant_id']"
              :options="merchantOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Merchant"
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Created Date"
            label-for="sent_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['created_at']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['created_at']"
                class="flatpickr-cancel"
                @click="clearDate('created_at')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Delivery Service Rating"
            label-for="rating"
          >
            <v-select
              id="rating"
              v-model="filters['rating']"
              :options="ratingOptions"
              :reduce="option => option.rating"
              label="name"
              autocomplete="nope"
              placeholder="Select Rating"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Software Rating"
            label-for="software_rating"
          >
            <v-select
              id="software_rating"
              v-model="filters['software_rating']"
              :options="softwareRatingOptions"
              :reduce="option => option.software_rating"
              label="name"
              autocomplete="nope"
              placeholder="Select Rating"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <div>
        <div
          class="mx-2 mt-2 mb-1"
        >
          <b-row class="d-sm-flex align-items-start">
            <b-col
              cols="6"
              md="2"
            >
              <b-form-group>
                <cleave
                  v-model="form.feedback_period"
                  class="form-control"
                  :raw="false"
                  :options="cleaveOptions"
                  placeholder="Feedback Period"
                  required
                />
              </b-form-group>
            </b-col>
            <b-overlay
              :show="updateFeedbackPeriodLoading"
              variant="light"
              opacity="0.30"
              blur="10px"
              rounded="sm"
            >
              <b-button
                v-can="'edit-feedback-setting'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                :disabled="updateFeedbackPeriodLoading"
                @click="onClickUpdateFeedbackPeriod()"
              >
                Update Feedback Period
              </b-button>
            </b-overlay>

            <b-col
              class="d-block d-sm-flex flex-wrap justify-content-end align-items-center mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`Feedback - Page ${page}.xls`"
                :bulkname="`Feedback.xls`"
                :fetch="getFeedbackNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :current-page="1"
            show-empty
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(message)="data">
              {{ data.value ? data.value.length > 100 ? data.value.substring(0, 100)+'...' : data.value : 'N/A' }}
            </template>
            <template #cell(rating)="data">
              <div class="d-flex justify-content-center align-items-center">
                <star-rating
                  v-model="data.value"
                  :star-size="20"
                  :read-only="true"
                  :show-rating="false"
                  :glow="2"
                />
              </div>
            </template>
            <template #cell(software_feedback)="data">
              {{ data.value ? data.value.length > 100 ? data.value.substring(0, 100)+'...' : data.value : 'N/A' }}
            </template>
            <template #cell(software_rating)="data">
              <div class="d-flex justify-content-center align-items-center">
                <star-rating
                  v-model="data.value"
                  :star-size="20"
                  :read-only="true"
                  :show-rating="false"
                  :glow="2"
                />
              </div>
            </template>
            <template #cell(created_at)="data">
              <div>
                {{ __dateTimeFormatter(data.value) }}
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="onClickView(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                  View
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>
    <view-feedback-notification-modal ref="view_feedback_notification_modal" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import StarRating from 'vue-star-rating'
import ViewFeedbackNotificationModal from '@/components/Notification/ViewFeedbackNotificationModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const feedbackRepository = RepositoryFactory.get('feedback')
const merchantRepository = RepositoryFactory.get('merchant')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BPagination,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    vSelect,
    flatPickr,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BCardActions,
    ViewFeedbackNotificationModal,
    Cleave,
    StarRating,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      // Filters Start
      filters: {},
      // Filters End
      avatarText,
      total: 0,
      meta: {},
      dataLoading: false,
      updateFeedbackPeriodLoading: false,
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      form: {},
      sortBy: 'created_at',
      sortDesc: true,
      merchantOptions: [],
      ratingOptions: [
        { name: 'No Rating', rating: -1 },
        { name: 'Rating 1', rating: 1 },
        { name: 'Rating 2', rating: 2 },
        { name: 'Rating 3', rating: 3 },
        { name: 'Rating 4', rating: 4 },
        { name: 'Rating 5', rating: 5 },
      ],
      softwareRatingOptions: [
        { name: 'No Rating', software_rating: -1 },
        { name: 'Rating 1', software_rating: 1 },
        { name: 'Rating 2', software_rating: 2 },
        { name: 'Rating 3', software_rating: 3 },
        { name: 'Rating 4', software_rating: 4 },
        { name: 'Rating 5', software_rating: 5 },
      ],
      cleaveOptions: {
        numeral: true,
        prefix: ' days',
        tailPrefix: true,
      },
      fields: [
        {
          key: 'merchant.name',
          label: 'Merchant',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'message',
          label: 'Delivery Service Feedback',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'rating',
          label: 'Delivery Service Rating',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'software_feedback',
          label: 'Software Feedback',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'software_rating',
          label: 'Software Rating',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      rows: [],
      json_fields: {
        Merchant: {
          field: 'merchant.name',
          callback: value => `"${value}"`,
        },
        'Delivery Service Feedback': {
          field: 'message',
          callback: value => (value ? `"${value}"` : 'N/A'),
        },
        'Delivery Service Rating': {
          field: 'rating',
          callback: value => (value ? `"${value}"` : 'N/A'),
        },
        'Software Feedback': {
          field: 'software_feedback',
          callback: value => (value ? `"${value}"` : 'N/A'),
        },
        'Software Rating': {
          field: 'software_rating',
          callback: value => (value ? `"${value}"` : 'N/A'),
        },
        'Created At': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
      },
    }
  },
  watch: {
    page() {
      this.getFeedback()
    },
    perPage() {
      this.getFeedback()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getMerchantList()
    this.getFeedbackSetting()
    this.getFeedback()
  },

  methods: {
    async getFeedbackSetting() {
      try {
        const { data } = (await feedbackRepository.getFeedbackSetting())
        this.form = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getFeedback() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await feedbackRepository.getFeedback(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getFeedbackNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await feedbackRepository.getFeedbackNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async onClickUpdateFeedbackPeriod() {
      try {
        this.updateFeedbackPeriodLoading = true
        const payload = {
          feedback_period: this.form.feedback_period.replace(' days', ''),
        }
        const res = await feedbackRepository.updateFeedbackSetting(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Feedback period updated successfully')
        } else {
          this.showErrorMessage('Couldn\'t update feedback period')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.updateFeedbackPeriodLoading = false
    },
    async getMerchantList() {
      this.merchantOptions = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await merchantRepository.getMerchantListForDropdown()).data.data
      if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchantOptions))
    },
    onClickView(data) {
      this.$refs.view_feedback_notification_modal.openModal(data, 'View Feedback')
    },
    // REQUIRED
    applyFilters() {
      this.getFeedback()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getFeedback()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getFeedback()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.getFeedback()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-flatpicker.scss';
</style>
